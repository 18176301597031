.color-nav {
  background-color: #e5f6f7;
}

.brand-logo {
  float: left;
}

.notification-item:hover {
  background-color: rgba(91, 185, 193, 0.5);
  transition: background-color 0.2s ease-in-out;
}

.simple-text {
  font-family: 'Poppins';
  font-size: '16px';
  color: #0b243c;
  word-break: break-all;
  white-space: pre-line;
}

.dropdown-accounts {
  position: relative;
  right: 0;
  left: auto;
  display: inline-block;
}

.dropdown-accounts-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown-accounts:hover .dropdown-accounts-content {
  display: block;
}

.myEditor div[style*="text-align:none"] {
  text-align: center !important;
}

.MuiAlert-icon {
  color: #ffffff !important;
}

.table-others-statistics-grid {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  text-align: left;
}

.animated-progress {
  height: 20px;
  border-radius: 20px;
  border: 1px solid #D3E0E0;
  overflow: hidden;
  position: relative;
  margin-top: 1px;
}

.MuiTooltip-popper {
  z-index: 100000000 !important;
}

.animated-progress span {
  color: #FFFFFF;
  height: 100%;
  position: absolute;
  padding-left: 11px;
  font-size: 12px;
  letter-spacing: 0px;
  font-family: Poppins;
  text-align: start;
  border-radius: 25px;
  background-color: #FFA500;
  transition-property: width;
  transition-duration: 500ms;
}

.table-grid-activity-history {
  display: grid;
  grid-template-columns: auto auto 5fr 1fr;
  text-align: left;
}

.table-grid-forms-stats {
  display: grid;
  grid-template-columns: auto auto auto;
  text-align: left;
}

.table-grid-activity-stats-patient {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  text-align: left;
}

.table-grid-full-stats-patient {
  display: grid;
  grid-template-columns: auto auto auto;
  text-align: left;
}

.table-grid-consommation-details {
  display: grid;
  grid-template-columns: auto auto auto auto;
  text-align: left;
}

.table-grid-consommation {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  text-align: left;
}

.table-grid-gestion-incidents {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  text-align: left;
}

.text-button {
  opacity: 1;
  text-decoration: none;
  font-family: Poppins;
  color: #0B243C;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.text-button:hover {
  opacity: 0.7;
  text-decoration: underline;
}

.table-grid-stacked-bars {
  display: grid;
  grid-template-columns: auto auto auto;
  text-align: left;
}

.table-grid-secteurs {
  display: grid;
  grid-template-columns: auto auto auto;
  text-align: left;
}

.table-grid-questionnaire-condition {
  display: grid;
  grid-template-columns: 100fr 100fr 10fr;
  text-align: left;
}

.table-grid-questionnaire-message-condition {
  display: grid;
  grid-template-columns: 100fr 100fr 100fr 10fr;
  text-align: left;
}

.table-grid-insi {
  display: grid;
  grid-template-columns: 30fr auto auto auto auto auto auto 230fr;
  text-align: left;
}

.table-grid {
  display: grid;
  grid-template-columns: 30fr auto auto auto auto auto 230fr;
  text-align: left;
}

.table-grid-import {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: left;
}

.table-grid-icon-edit {
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  background-color: transparent;
  border-bottom: 1px solid #E6EBF0;
}

.table-grid-select {
  padding-left: 10px;
  padding-right: 10px;
  background-color: transparent;
  border-bottom: 1px solid #E6EBF0;
}

.table-grid-title {
  font-family: Poppins;
  text-align: left;
  font-weight: normal;
  color: #8D9899;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 8px;
  letter-spacing: 0px;
  border-bottom: 1px solid #657273;
}

.table-grid-text {
  font-family: Poppins;
  text-align: left;
  font-weight: normal;
  color: #4D473D;
  font-size: 14px;
  padding-left: 20px;
  padding-bottom: 19px;
  padding-top: 19px;
  padding-right: 20px;
  letter-spacing: 0px;
  border-bottom: 1px solid #E6EBF0;
}

.text-logo {
  text-align: left;
  float: left;
}

div>.css-1s2u09g-control {
  margin: 0px 0px 0px 0px;
  width: 100%
}

.link {
  color: #fff;
  text-decoration: underline;
}

.font-link {
  font-family: "Poppins", sans-serif;
  color: #000;
  font-size: 13px;
  color: rgb(11, 36, 60);
  margin-left: 10px;
}

.nav-item {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-right: 8px;
  color: #8d9899;
  background-color: #d4e0e0;
  font-family: "Poppins", sans-serif;
}

.nav-link {
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-link:focus,
.nav-link:hover {
  color: #000000;
}

.nav-tabs .nav-item.show,
.nav-tabs .nav-link.active {
  justify-content: center;
  align-items: center;
  opacity: 1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #0b243c;
  font-family: "Poppins", sans-serif;
  background: #FAFCFC 0% 0% no-repeat padding-box;
  font-weight: bold;
  border-left: 1px solid #E1EBEB;
  border-right: 1px solid #E1EBEB;
  border-top: 1px solid #E1EBEB;
  border-bottom: 0px;
  height: 50px;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.nav-tabs .nav-link {
  justify-content: center;
  align-items: center;
  border: none;
  opacity: 1;
  color: #8D9899;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font: normal normal medium 18px/27px Poppins;
  height: 50px;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.vl-l {
  height: 92px;
  float: left;
  border: 1px solid #edf6f7;
}

.vl-r {
  height: 92px;
  float: right;
  border: 1px solid #edf6f7;
}

.FlexContainerCard {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  overflow: auto;
  flex-direction: row;
}

.conseilAded {
  display: grid;
  height: 120px;
  grid-template-columns: 8.5% 1% 78.5% 1% 5%;
}

.select_options {
  background: #fafcfccc 0% 0% no-repeat padding-box;
  border: 1px solid #cfe4e6;
  border-radius: 10px;
  opacity: 1;
}

.FlexContainer {
  float: left;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
}

.FlexContainer div {
  margin-top: 9px;
  margin-bottom: 9px;
  margin-left: 15px;
  margin-right: 15px;
}

.title-card {
  float: left;
  position: relative;
}

.button-create {
  z-index: 1;
  margin-top: 14px;
  background: #0b243c 0% 0% no-repeat padding-box;
  box-shadow: 2px 6px 10px #0f213467;
  border-radius: 18px;
  width: 312px;
  height: 36px;
  position: absolute;
  right: 0px;
  color: white;
  text-align: center;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  font-size: 13px;
  display: block;
  margin-left: auto;
  margin-right: 40px;
  line-height: 34px;
}

.more-button {
  z-index: 1;
  background: #0b243c 0% 0% no-repeat padding-box;
  border-radius: 10px;
  height: 35px;
  width: 210px;
  position: relative;
  right: 0px;
  color: white;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: -30px;
}

.force_display {
  z-index: 1040;
  position: absolute;
  display: flex;
  margin: auto;
  margin-top: 80px;
}

.force_hide {
  z-index: 0;
  display: flex;
  margin: auto;
}

.import-button {
  z-index: 1;
  margin-top: 10px;
  background: #0b243c 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 171px;
  height: 60px;
  position: relative;
  right: 0px;
  color: white;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  font-size: 13px;
  display: block;
}

.FlexContainer-r {
  margin-top: 10px;
  margin-left: 25px;
  margin-right: -7px;
  float: right;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.FlexContainer-r div {
  margin: 9px;
}

.FlexContainer-body {
  float: left;
  width: 90%;
  margin-left: 25px;
  margin-top: 15px;
  margin-right: 25px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.FlexContainer-body p {
  margin-top: 0px;
}

.FlexContainer-body-collapse {
  float: left;
  width: 90%;
  height: 108px;
  margin-left: 35px;
  margin-top: 10px;
  margin-right: 35px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  overflow: auto;
  overflow: hidden;
}

.FlexContainer-body-collapse p {
  margin-top: 0px;
}

.FlexContainer-text {
  float: left;
  width: 90%;
  margin-left: 25px;
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.FlexContainer-text p {
  margin-top: 0px;
}

.FlexContainer-text-collapse {
  float: left;
  width: 90%;
  margin-left: 25px;
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
}

.FlexContainer-text-collapse p {
  margin-top: 0px;
}

.conseil-cardactive {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 12px #00000014;
  border-radius: 15px;
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.125);
  text-align: left;
  font: normal normal normal 16px/22px Poppins;
  letter-spacing: 0px;
  opacity: 1;
  margin: 8px;
}

.list_keywords {
  list-style-type: none;
  display: flex;
  margin: auto;
  min-height: 50px;
  padding: 0;
}

.conseil-cardactive svg {
  float: left;
}

@keyframes slide-to-up-effect {
  from {
    opacity: 0;
    bottom: -200px;
  }

  to {
    opacity: 1;
    bottom: 0px;
  }
}

.pdf-page-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 12px #00000014;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 50px;
  width: 850px;
}

.conseil-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 12px #00000014;
  border-radius: 15px;
  position: relative;
  display: block;
  padding: 0.5rem 0rem;
  color: #212529;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.125);
  text-align: left;
  font: normal normal normal 16px/22px Poppins;
  letter-spacing: 0px;
  opacity: 1;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  margin-top: 8px;
  animation-name: slide-to-left-effect;
  animation-duration: 1.5s;
}


.conseil-card-without-animation {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 12px #00000014;
  border-radius: 15px;
  position: relative;
  display: block;
  padding: 0.5rem 0rem;
  color: #212529;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.125);
  text-align: left;
  font: normal normal normal 16px/22px Poppins;
  letter-spacing: 0px;
  opacity: 1;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  margin-top: 8px;
}

.conseil-card p {
  text-align: left;
  margin-left: 25px;
}

.conseil-card svg {
  float: left;
  clear: both;
}

.conseil-card-title {
  color: #8d9899;
  font-size: 12px;
  margin-top: 0px;
  margin-left: 25px;
}

.conseil-card-pack {
  text-align: left;
  position: relative;
}

.conseil-card-pack::after {
  content: "";
  display: block;
  width: 100%;
  margin: auto;
  height: 107px;
  position: sticky;
  bottom: 0;
  pointer-events: none;
  background: transparent linear-gradient(180deg, #ffffff00 0%, #ffffffcd 48%, #ffffff 100%) 0% 0% no-repeat padding-box;
}

.my_margin {
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.font-link-bold {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: rgb(0, 0, 0);
}

.font-link-blue-bold {
  font-family: "Poppins", sans-serif;
  color: #5bb9c1;
  font-weight: bold;
}

.card {
  background-color: #eff9fa;
  border: none;
}

button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  font-size: 18px;
}

button:hover,
a:hover {
  opacity: 0.7;
}

.button-div {
  padding: 60px;
  position: relative;
}

.fav-button {
  border: none;
  height: 40px;
  width: 50px;
  font-size: 15px;
  background-color: #000;
  color: rgb(255, 238, 0);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  margin-top: 8px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
}

.phantom-gradient {
  margin-top: -16px;
}

.gradient_filter {
  background: transparent linear-gradient(180deg, #fafcfc 0%, #f5f7f700 100%) 0% 0% no-repeat padding-box;
}

.phantom-gradient {
  margin-top: -16px;
}

.search-field {
  padding: 10px 35px 10px 15px;
  color: #fff;
  border-color: #d4e0e0;
  border-radius: 15px;
  outline: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #0000000d;
  border: 1px solid #d4e0e0;
  width: 100% !important;
  color: #000;
  position: relative;
}

.filter-by {
  margin-top: 20px;
  margin-bottom: 20px;
}

.filter-col {
  display: grid;
  grid-template-columns: 0.3fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". . ."
    "filter-by nuage nuage"
    ". . .";
}

.nuage {
  margin: 1px;
  background: #fafcfccc 0% 0% no-repeat padding-box;
  border: 1px solid #cfe4e6;
  border-radius: 10px;
  opacity: 1;
  display: inline-block;
}

.text-nuage {
  margin-left: 8px;
  text-align: left;
  font: normal normal normal 16px Poppins;
  letter-spacing: 0px;
  color: #8d9899;
}

.horizontal-bar {
  border: 1px solid #e6ebf0;
  height: 0px;
}

.count_number {
  text-align: left;
  font: normal normal bold 11px/32px Poppins;
  letter-spacing: 0px;
  color: #5bb9c1;
  float: left;
  margin: -5px 10px 0px 0px;
}

.place_number {
  display: flex;
  flex-direction: row;
}

.filter-col1 {
  grid-area: nuage;
}

.filter-col2 {
  grid-area: filter-by;
}

.title-in-tabs {
  text-align: left;
  font: normal normal 600 20px/30px Poppins;
  letter-spacing: 0px;
  color: #23595d;
  opacity: 1;
}

.description-in-tabs {
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #657273;
}

.search-button {
  z-index: 1;
  background: transparent;
  border: none;
  outline: none;
  margin-left: -40px;
}

.search-button-grey {
  z-index: 1;
  background: transparent;
  border: none;
  outline: none;
  margin-left: -35px;
}

.search-button img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.fav-button:active i {
  animation: item 1s ease-in forwards;
}

@keyframes item {
  0% {
    top: 20%;
    color: #000;
  }

  25% {
    top: 60%;
    color: #000;
  }

  50% {
    top: 100%;
  }

  75% {
    top: 60%;
  }

  0% {
    top: 0%;
  }
}

.conseil-send-title {
  text-align: left;
  font: normal normal 600 20px/30px Poppins;
  letter-spacing: 0px;
  color: #0b243c;
}

.conseil-send-desc {
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #657273;
  display: inline-block;
  margin-left: 10px;
}

.list-conseil {
  padding-left: 0;
}


@keyframes slide-to-left-effect {
  from {
    opacity: 0;
    left: 100px;
  }

  to {
    opacity: 1;
    left: 0px
  }
}

.Conseil-send-li {
  position: relative;
  font: normal normal normal 16px/25px Poppins;
  display: flex;
  list-style: none;
  color: white;
  background: #5bb9c1 0% 0% no-repeat padding-box;
  border-radius: 12px;
  padding: 15px;
  margin-top: 10px;
  font-size: 1rem;
  animation-name: slide-to-left-effect;
  animation-duration: 1.5s;
}

div[id=display-text-and-image] p {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

div[id=display-text-and-image] p span {
  font-weight: 400;
}

.Conseil-send-li>p {
  margin-left: 15px;
}

.Conseil-send-li>div {
  margin-left: auto;
}

.Conseil-send-li>div>a {
  margin-left: 10px;
}

.swagger-ui .responses-inner {
  max-width: 80vw;
}

.conseil-send-drop {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px dashed #8d9899;
  border-radius: 12px;
  width: 100%;
  height: 90px;
  display: inline-block;
  line-height: 100px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.conseil-send-drop p {
  display: inline-block;
  vertical-align: middle;
  font: italic normal 600 13px/20px Poppins;
  color: #657273;
  width: 165px;
}

.last-char {
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #8d9899;
}

.input-conseil {
  background: #edf2f280 0% 0% no-repeat padding-box;
  border: 1px solid #d3e0e0;
  border-radius: 4px;
  /* width: 469px; */
  width: 100%;
  height: 92px;
}

.input-conseil::placeholder {
  text-align: left;
  font: italic normal normal 14px/21px Poppins;
  color: #657273;
}

.conseil-send-checkbox {
  background: #edf2f2 0% 0% no-repeat padding-box;
  border: 1px solid #d3e0e0;
  border-radius: 25px;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  display: inline-block;
}

.send_date_div {
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.send_date_div>.item1 {
  margin: auto;
  margin-right: 10px;
}

.send_date_div>.item2 {
  margin: auto;
  margin-right: 5px;
}

.send_date_div>.item4 {
  margin-right: 5px;
}

.send_date_div>.item3 {
  margin: auto;
}

.container-send {
  align-items: inherit;
}

.icon-send {
  float: left;
}

.text-send {
  text-align: left;
  font: normal normal 600 20px/30px Poppins;
  letter-spacing: 0px;
  color: #0b243c;
}

.send-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  gap: 5px;
  padding: 5px;
}

.send-container-conseil {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
}

.freeTextDisplay {
  font: normal normal bold 16px/25px Poppins;
  margin-left: 5px;
  color: #5bb9c1;
}

.freeTextDisplay:hover {
  cursor: pointer;
  text-decoration: underline;
  transition: 0.5s;
}

.border-animation {
  transition-property: border;
  transition-delay: 250ms;
  transition-duration: 2000ms;
}


.opacity-animation:hover {
  transition: opacity 0.3s ease;
  opacity: 0.7;
  cursor: pointer;
}

.normal-text {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.champ-animation {
  transition-property: box-shadow, opacity;
  transition-duration: 1500ms;
}

.input-error-add-style {
  border: 1px solid rgb(255, 49, 0),
}

.opacity-animation-up {
  animation-name: opacity-effect;
  animation-duration: 15s;
}

@keyframes opacity-effect {
  0% {
    opacity: 0.5;
  }

  15% {
    opacity: 0.95;
  }

  85% {
    opacity: 0.95;
  }

  100% {
    opacity: 0;
  }
}

.number-input-no-apparence {
  -moz-appearance: textfield;
}

.number-input-no-apparence:-webkit-inner-spin-button,
.number-input-no-apparence:-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.setFreeText {
  display: flex;
  flex-direction: row;
  color: #657273;
  font: normal normal normal 16px/25px Poppins;
}

.send-container>div {
  border-radius: 5px;
}

.send-text {
  width: 38px;
  height: 32px;
  background: #edf2f2 0% 0% no-repeat padding-box;
  border: 1px solid #d3e0e0;
  border-radius: 3px;
  display: inline-block;
}

.send-date {
  width: 171px;
  height: 32px;
  background: #edf2f2 0% 0% no-repeat padding-box;
  border: 1px solid #d3e0e0;
  border-radius: 3px;
  text-align: center;
  font: normal normal normal 14px/21px Poppins;
  color: #657273;
  opacity: 1;
}

.send-date-hours {
  width: 78px;
  height: 32px;
  background: #edf2f2 0% 0% no-repeat padding-box;
  border: 1px solid #d3e0e0;
  border-radius: 3px;
  opacity: 1;
  text-align: center;
  color: #657273;
  font: normal normal normal 14px/21px Poppins;
}

.send-date-hours::placeholder {
  color: #657273;
  opacity: 0.15;
  font: italic normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  text-align: 'center';
}


.send-button {
  width: 146px;
  height: 51px;
  background: #ffa500 0% 0% no-repeat padding-box;
  box-shadow: 2px 6px 10px #e6a55c66;
  border-radius: 18px;
  text-align: center;
  margin: auto;
  font: normal normal bold 16px/25px Poppins;
  color: #fff;
  line-height: 51px;
  font-size: 16px;
  margin-top: 10px;
}

.input-patient {
  background: #f0f2f2 0% 0% no-repeat padding-box;
  border: 1px solid #8d9899;
  border-radius: 10px;
  height: 60px;
  padding: 12px 20px;
  margin-top: 10px;
}

.input-patient::placeholder {
  text-align: left;
  font: italic normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #657273;
  right: 5px;
  text-indent: 10px;
}

.tick-mark {
  position: relative;
  display: inline-block;
  margin-bottom: 6px;
  width: 11px;
  height: 10px;
}

.tick-mark::before {
  position: absolute;
  left: 0;
  top: 50%;
  height: 50%;
  width: 3px;
  background-color: #5bb9c1;
  content: "";
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}

.tick-mark::after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100%;
  background-color: #5bb9c1;
  content: "";
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}

.text-patient {
  color: #0b243c;
  font: normal normal bold 22px/33px Poppins;
  letter-spacing: 0px;
}

.phone-number {
  text-align: center;
  font: normal normal normal 18px/27px Poppins;
  letter-spacing: 0px;
  color: #657273;
  line-height: 0px;
}

.edit-button {
  text-align: center;
  font: normal normal 600 13px/20px Poppins;
  line-height: 34px;
  color: #5bb9c1;
}

.login-background {
  background: transparent linear-gradient(180deg, #e4f6f7 0%, #f8fbfb 100%) 0% 0% no-repeat padding-box;
}

@media (min-width: 768px) {
  .col-md-4 {
    width: 36.333333%;
  }

  .col-md-8 {
    width: 63.666667%;
    max-width: 1600px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e5f6f7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a3c4ce75;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a8b5b6b4;
  border-radius: 10px;
}

.img-zoom-effect {
  opacity: 1;
  transition: transform 800ms;
  transform: scale(1.0);
  position: relative;
  z-index: 10;
}

.img-zoom-effect:hover {
  opacity: 1;
  cursor: zoom-in;
  transform: scale(1.0);
}

.img-zoom-effect-loaded {
  opacity: 1;
  transition: transform 800ms;
  position: relative;
  z-index: 10;
  transform: scale(2.5);
}

.img-zoom-effect-loaded:hover {
  opacity: 1;
  cursor: zoom-out;
  transform: scale(2.5);
}