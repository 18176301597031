.blue-button {
  border-radius: 18px;
  text-align: center;
  font: normal normal bold 16px/25px Poppins;
  color: #ffffff;
  background: #5BB9C1 0% 0% no-repeat padding-box;
  height: 51px;
  box-shadow: 2px 6px 10px #E6A55C66;
  margin: 0px 0px 0px 0px;
  padding: 0px 30px 0px 30px;
}
