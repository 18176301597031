.orange-button {
  box-shadow: 2px 6px 10px #e6a55c66;
  border-radius: 18px;
  text-align: center;
  font: normal normal bold 16px/25px Poppins;
  color: #ffffff;
  height: 51px;
  margin: 0px 0px 0px 0px;
  padding: 0px 30px 0px 30px;
}

.button-animation {
  animation-name: increase-opacity-effect;
  animation-duration: 700ms;
}

.enable-orange-button {
  background: #ffa500 0% 0% no-repeat padding-box;
}

.disable-orange-button {
  box-shadow: none;
  background: #8d9899 0% 0% no-repeat padding-box;
  cursor: not-allowed;
}

.disable-orange-button:hover {
  opacity: 1;
}

.disable-orange-button > * {
  pointer-events: none;
}

@keyframes increase-opacity-effect {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}