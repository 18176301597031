.send_array {
  display: flex;
  flex-direction: column;
}

.top_send_array {
  display: flex;
  flex-direction: row;
  font: normal normal normal 16px/25px Poppins;
  color: #657273;
  margin-left: 10px;
}

.destinataires {
  margin-left: 7.5%;
}

.messages {
  margin-left: 5%;
}

.bar {
  border: 1px solid #657273;
  width: 100%;
}

.all_send {
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.date_send {
  margin: 0px 10px 10px 0px;
  font: normal normal normal 14px/21px Poppins;
  color: #4d473d;
}

.destinataire {
  margin: 0px 10px 10px 10px;
  font: normal normal normal 14px/21px Poppins;
  color: #4d473d;
}

.message_and_title {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.message {
  font: normal normal normal 14px/22px Poppins;
  color: #4d473d;
}

.title {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  font: normal normal normal 12px/18px Poppins;
  color: #8d9899;
}

.title_bold {
  font: normal normal medium 12px/18px Poppins;
  color: #8d9899;
}
