p {
  font-family: Poppins;
  span {
    font-weight: bold;
  }
}

.place_number {
  text-align: left;
  margin-top: 10px;

  img {
    height: 70px;
    width: 70px;
    min-width: 70px;
    margin-right: 20px;
    object-fit: cover;
  }
}

.cross {
  margin: auto;
  svg {
    color: red;
  }
}

.title_detail {
  p {
    font-size: 1.4em;
    color: black;
    font-family: "Poppins", sans-serif;
  }
  .titre_Conseil {
    font-weight: bold;
  }
  display: flex;
  flex-direction: column;
  margin: 0;
}

.FlexContainer-text-collapse {
  height: 100%;
}

.FlexContainer-body-collapse {
  .FlexContainer {
    margin-left: 0;
    margin: auto;
  }
}

.content_attachment {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 15px;

  p {
    margin: auto;
  }

  .content {
    margin: 0;
    white-space: pre-line;
  }

  .place_number {
    .attachment {
      margin: 0;
      margin-top: auto;
      font-size: 0.8rem;
    }
  }
}

.admin {
  span {
    font-size: 2rem;
    margin: 25px;
  }
}

.adminDiv {
  display: flex;
  width: 80%;
  margin: auto;

  .list-group {
    margin: auto;
  }
}
