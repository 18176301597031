.return-button {
  text-align: center;
  font: normal normal medium 13px Poppins;
  color: #FFFFFF;
  background-color: #5BB9C1;
  letter-spacing: 0px;
  border-radius: 10px;
  width: 200px;
  max-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px 10px 10px;
}