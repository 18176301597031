.green-button {
  border-radius: 3px;
  text-align: center;
  font-size: 15px;
  font-family: Poppins;
  color: #ffffff;
  padding: 12px 24px;
  display: block;
  min-height: 40px;
  padding-top: 0;
  padding-bottom: 0;
}

.button-animation {
  animation-name: increase-opacity-effect;
  animation-duration: 700ms;
}

.enable-green-button {
  background: #4DA180 0% 0% no-repeat padding-box;
}

.disable-green-button {
  box-shadow: none;
  background: #8d9899 0% 0% no-repeat padding-box;
  cursor: not-allowed;
}

.disable-green-button:hover {
  opacity: 1;
}

.disable-green-button > * {
  pointer-events: none;
}

@keyframes increase-opacity-effect {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}