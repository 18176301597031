.select-options {
  background: #fafcfccc 0% 0% no-repeat padding-box;
  border: 1px solid #cfe4e6;
  font: normal normal normal 16px Poppins;
  color: #8D9899;
  border-radius: 10px;
  opacity: 1;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  height: 38px;
}

.clear-text-button {
  border: none;
  background-color: inherit;
  cursor: pointer;
  display: inline-block;
  cursor: 'pointer';
  font: normal normal normal 16px Poppins;
  color: #8D9899,
}

.clear-text-button:hover {
  text-decoration: underline;
}

.filter-by-text {
  font: normal normal normal 16px Poppins;
  letter-spacing: 0px;
  color: #8D9899;
}