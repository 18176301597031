.keywords_active {
  display: flex;
  margin: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  font: normal normal bold 15px/15px "Comfortaa";
  min-height: 37px;
  letter-spacing: 0px;
  color: #ffa500;
  background: #ffa500 0% 0% no-repeat padding-box;
  color: white;
  border: 1px solid #ffa500;
  border-radius: 40px;
  transition: 0.5s;

  p {
    margin: auto;
  }

  a {
    margin: auto;
    margin-left: 10px;
  }
}

.no_results {
  display: flex;
  flex-direction: column;
  .button-create {
    position: relative;
    margin-right: 150px;
    margin-bottom: 25px;
  }
}

.keyword_div {
  display: flex;
  width: 100%;

  .list_keywords {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px 0px;
    grid-template-areas:
      ". . ."
      ". . .";
    width: 100%;

    .keywords {
      display: flex;
      margin: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 20px;
      padding-right: 20px;
      min-height: 37px;
      max-width: 250px;

      font: normal normal bold 15px/15px "Comfortaa";
      border: 1px solid var(--unnamed-color-ffa500);
      letter-spacing: 0px;
      color: #ffa500;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #ffa500;
      border-radius: 40px;
      transition: 0.5s;

      p {
        margin: auto;
        color: #ffa500;
        margin-right: 5px;
      }

      a {
        color: #ffa500;
        margin: auto;
      }
    }
  }
}

.FlexContainer-text-collapse {
  height: 100%;
  margin-left: 0;
}

.content_attachment::-webkit-scrollbar {
  width: 5px;
}

.title_detail {
  p {
    font-size: 1.4em;
    color: black;
    font-family: "Poppins", sans-serif;
  }
  .titre_Conseil {
    font-weight: bold;
  }
  display: flex;
  flex-direction: column;
  margin: 0;
}

.content_attachment {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  scrollbar-width: "3px";

  img {
    min-width: 70px;
    margin-right: 20px;
    object-fit: cover;
  }
  .content {
    margin: 0;
    white-space: pre-line;
  }

  .attachment {
    margin: 0;
    margin-top: auto;
    font-size: 0.8rem;
  }
}

.filter-col1 {
  select {
    width: 245px;
  }
}

@media only screen and (min-width: 2000px) and (max-width: 2300px) {
  .filter-col1 {
    .select_options {
      width: 300px;
    }
    select {
      // width: 500px;
    }
  }
}

@media only screen and (min-width: 2300px) and (max-width: 3000px) {
  .filter-col1 {
    .select_options {
      width: 320px;
    }
    select {
      // width: 500px;
    }
  }
}

@media (max-width: 1715px) {
  .filter-col1 {
    select {
      width: 200px;
    }
  }
}

@media (max-width: 1500px) {
  .keyword_div {
    .list_keywords {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 10px 0px;
      grid-template-areas:
        ". . ."
        ". . .";
    }
  }
  .filter-col2 {
    width: 110%;
    margin-right: 15px;
  }
  .filter-col1 {
    width: 110%;
    margin-left: 15px;
    select {
      width: 150px;
    }
  }
}
