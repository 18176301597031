.sheduled-table-grid-title {
  font-family: Poppins;
  text-align: left;
  font-weight: normal;
  color: #8D9899;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 12px;
  letter-spacing: 0px;
  border-bottom: 1px solid #657273;
}

.sheduled-table-grid-content {
  font-family: Poppins;
  text-align: left;
  font-weight: normal;
  color: #4D473D;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 19px;
  padding-top: 19px;
  letter-spacing: 0px;
  border-bottom: 1px solid #E6EBF0;
}

.sheduled-table-grid {
  display: grid;
  grid-template-columns: 141fr 220fr 750fr;
  text-align: center;
}

.sheduled-table-grid-sended {
  display: grid;
  grid-template-columns: 141fr 220fr 750fr 400fr;
  text-align: center;
}