.modal-overlay-edit {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1040;
  opacity: 1;
}

.modal-wrapper {
  background-color: #d5f8fa3d;
}

.sumbited {
  // background-color: black;
}

.ConseilEdided {
  backdrop-filter: blur(2px);
}

.create_conseil_modal {
  box-shadow: 4px 2px 16px -7px rgba(0, 0, 0, 0.1);
}
