//------------------------
// ImportFileMessage.scss
//------------------------

.import-file-messsage {
    .label-import-file {
        cursor: pointer;
        border: 2px dashed #8d9899;
        border-radius: 12px;
        width: 100%;
        background: #ffffff 0 0 no-repeat padding-box;
        padding: 15px;

        p {
            display: inline-block;
            vertical-align: middle;
            font: italic normal 600 13px/20px Poppins;
            color: #657273;
            text-align: center;
            width: 100%;
        }

        span {
            display: inline-block;
            vertical-align: middle;
            width: 100%;
            text-align: center;
            font: normal normal 500 13px/20px Poppins;
            color: rgba(#657273, 0.5);
        }
    }

    .import-file-input {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        margin-bottom: 10px;
        display: none;
    }

    .file-preview {
        margin: 10px 0;
        width: 100%;
        height: auto;
        border-radius: 12px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 7px;
        }

        iframe {
            width: 114px;
        }

        audio {
            width: 100%;
            object-fit: cover;
            border-radius: 7px;
        }

        .remove {
            position: absolute;
            top: -8px;
            right: -8px;
            cursor: pointer;
            background: #ffffff 0 0 no-repeat padding-box;
            border: 1px solid rgba(141, 152, 153, 0.34);
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font: normal normal 500 13px/20px Poppins;
            color: #657273;
            opacity: .5;
        }
    }

    .error {
        color: #ff0000;
        font-size: 12px;
        margin: 5px;

    }
}
