.input-phone-multiple {
  background: #F0F2F2 0% 0% no-repeat padding-box;
  border: 1px solid #8D9899;
  border-radius: 10px;
  opacity: 1;
  text-align: left;
  height: 30px;
  font: normal normal normal 14px Poppins;
  padding: 5px;
  width: '500px'
}

.input-phone-multiple::placeholder {
  font: italic normal normal 14px/21px Poppins;
  color: #657273;
}

