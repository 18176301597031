@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap");

html {
  padding: 0;
  margin: 0;
  min-height: 100%;
}

body {
  min-height: 100%;
  background: transparent linear-gradient(180deg, #e4f6f7 0%, #f8fbfb 100%) fixed no-repeat;
  background: -webkit-linear-gradient(#e4f6f7, #f8fbfb) fixed no-repeat;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
