.date_send {
  width: 12%;
  margin: 0;
}

.all_send {
  .import-button {
    margin: 0;
    height: 50px;
  }
}
.destinataire {
  width: 12%;
  margin-right: 45px;
  margin-left: 20px;
}

.message_and_title {
  font-family: "Poppins", sans-serif;
}

@media (max-width: 1250px) {
  .row {
    flex-direction: column;
  }
  .Profil_comp {
    width: 97%;
    margin-bottom: 25px;
    .card {
      padding-left: 12px !important;
      padding-right: 12px !important;

      .box_menu {
        flex-direction: row;
        margin-left: 0;

        .align_bar {
          margin-left: 25px;
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .Profil_comp {
    .card {
      .box_menu {
        flex-direction: column;
        margin-left: 0;
      }
    }
  }
}
