.footer {
  background: #0f2134 0% 0% no-repeat padding-box;

  text-align: center;
}

.footer-link {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #ffa500;
}

.footer-second-link {
  color: #8d9899;
  font-size: 14px;
}

.footer-second-link > a {
  text-decoration: none;
  color: #8d9899;
}

.phantom {
  display: block;
  padding: 20px;
  height: 150px;
  width: 100%;
}

.phantom2 {
  display: block;
  padding: 20px;
  height: 10px;
  width: 100%;
}
