.login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10%;
}

.box-register {
  background-image: url("../assets/BGLogin.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  text-align: center;
  font: normal normal normal 16px/24px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  height: 100%;
  padding: 10px;
}

.box-register p {
  width: 347px;
}

.box-register div {
  margin-left: 150px;
  margin-right: 150px;
}

.box-register-login {
  display: grid;
  grid-template-columns: 40% 60%;
}

.box-login {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 32px #00000014;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box-login div {
  margin-left: 150px;
  margin-right: 150px;
}

.box-login a {
  text-align: left;
  text-decoration: underline;
  font: normal normal bold 16px/25px Poppins;
  letter-spacing: 0px;
  color: #ffa500;
}

.logo-login {
  margin: 40px;
  margin-top: 23%;
}

.log-box-title {
  text-align: center;
  font: normal normal normal 26px/39px Poppins;
  letter-spacing: 0px;
  color: #0b243c;
  margin-top: 70px;
  margin-bottom: 35px;
}

.log-box-title-register {
  text-align: center;
  font: normal normal normal 26px/39px Poppins;
  letter-spacing: 0px;
  color: #0b243c;
  margin-top: 10px;
  margin-bottom: 35px;
}

.input-login {
  background: #d4e0e01a 0% 0% no-repeat padding-box;
  border: 1px solid #d4e0e0;
  border-radius: 15px;
  padding: 10px;
  width: 80%;
  height: 56px;
  margin: 10px 0px 0px 0px;
}

.input-login::placeholder {
  text-align: left;
  font: italic normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #657273;
  text-indent: 10px;
}

.input-login2 {
  background: #d4e0e01a 0% 0% no-repeat padding-box;
  border: 1px solid #d4e0e0;
  border-radius: 15px;
  width: 100%;
  height: 56px;
  margin: auto;
}

.input-login2::placeholder {
  text-align: left;
  font: italic normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #657273;
  text-indent: 10px;
}

.form-login {
  margin-top: 8px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-button {
  background: #ffa500 0% 0% no-repeat padding-box;
  box-shadow: 2px 6px 10px #e6a55c66;
  border-radius: 18px;
  width: 188px;
  height: 51px;
  text-align: center;
  font: normal normal bold 16px/25px Poppins;
  color: #ffffff;
  margin: 0 auto;
}

.register-button {
  background: #5bb9c1 0% 0% no-repeat padding-box;
  box-shadow: 2px 6px 10px #0d1212;
  border-radius: 18px;
  width: 218px;
  height: 51px;
  margin: 30px;
  text-align: center;
  font: normal normal bold 16px/25px Poppins;
  letter-spacing: 0px;
  color: inherit;
  text-decoration: none;
}

.psc-box {
  margin: 65px;
  border: 1px solid #6d6d6d;
  border-radius: 18px;
  width: 268px;
  height: 64px;
}

.login-footer {
  text-align: center;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #8d9899;
}

.login-footer > a {
  text-decoration: none;
  color: #8d9899;
}

.phantom {
  display: block;
  padding: 20px;
  height: 150px;
  width: 100%;
}

.phantom2 {
  display: block;
  padding: 20px;
  height: 50px;
  width: 100%;
}

.card-panel {
  -webkit-transition: -webkit-box-shadow 0.25s;
  transition: -webkit-box-shadow 0.25s;
  transition: box-shadow 0.25s;
  transition: box-shadow 0.25s, -webkit-box-shadow 0.25s;
  padding: 24px;
  margin: 0.5rem 0 1rem 0;
  border-radius: 2px;
  background-color: #fff;
  position: absolute;
}

.box-login div {
  margin-left: 150px;
  margin-right: 150px;
}

.red.accent-1 {
  background-color: #ff8a80 !important;
}

.civility-radio {
  display: flex;
  flex-direction: row;
  margin-left: 15px;
}

.menu-deroulant {
  background: #fafcfccc 0% 0% no-repeat padding-box;
  border: 1px solid #cfe4e6;
  border-radius: 15px;
  color: #8d9899;
  height: 55px;
  width: 60%;
}

.civility-checkbox {
  background: #edf2f2 0% 0% no-repeat padding-box;
  border: 1px solid #d3e0e0;
  border-radius: 25px;
}

.box-registration {
  display: flex;
  flex-direction: row;
  /*background: #ffffff 0% 0% no-repeat padding-box;*/
  background: transparent;
  /*box-shadow: 4px 4px 32px #00000014;*/
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 60%;
  align-items: center;
}

.containerSelect {
  display: flex;
  width: 100%;
}

.containerSelect > .css-b62m3t-container {
  margin: auto;
  width: 90%;
}

div > .css-1s2u09g-control {
  margin: auto;
  width: 70%;
}

.captcha {
  margin-top: 10px;
  display: inline-block;
}

.box-registration a {
  text-align: left;
  text-decoration: underline;
  font: normal normal bold 16px/25px Poppins;
  letter-spacing: 0px;
  color: #ffa500;
}

.nuage {
  margin: 5px;
  background: #fafcfccc 0% 0% no-repeat padding-box;
  border: 1px solid #cfe4e6;
  border-radius: 10px;
  opacity: 1;
  display: inline-block;
}

.back_button {
  border-radius: 255px;
  height: 20px;
  width: 20px;
}

.modal_error {
  position: absolute;
}
.alert {
  margin-top: 5px;
}

/* Réponsive */
@media (min-width: 1900px) {
  .box-registration {
    width: 30%;
  }
}

@media (max-width: 1000px) {
  .box-register-login {
    display: flex;
    flex-direction: column-reverse;
    width: 550px;
  }

  .box-register > img {
    margin-top: 30px;
  }
  .box-register {
    position: relative;
    border-top-right-radius: 1px;
    border-top-left-radius: 1px;
    border-bottom-right-radius: 15px;
    padding-top: 20px;
    z-index: 1;
  }
  .box-login {
    position: relative;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 0px;
    z-index: 3;
    bottom: -15px;
  }
  .box-registration {
    width: 60%;
  }
}

@media (max-width: 620px) {
  .login {
    width: 100%;
    margin: auto;
  }

  .box-register-login {
    overflow: hidden;
  }

  .box-registration {
    margin-top: 25px;
    width: 75%;
  }

  .box-register {
    margin: auto;
    width: 90%;
  }
  .box-login {
    margin-top: 30px;
    margin: auto;
    width: 90%;
  }

  .log-box-title {
    width: 100%;
    margin-bottom: 15px;
  }
  .psc-box {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .phantom {
    height: 0px !important;
    visibility: none;
    padding: 10px !important;
  }
  .phantom2 {
    padding: 0px !important;
  }
}
