.card-bodyPatientCard {
  padding-left: 5px;
}

.drowDownSearch {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  border-radius: 0 0 10px 10px;

  .PatientUnit > p {
    height: 25px;
    letter-spacing: 0px;
    margin-bottom: 0;
  }
  .PatientUnit:hover {
    background: #cacaca 0% 0% no-repeat padding-box;
    transition: 0.5s;
  }
}
.dropdown-menu {
  width: 345px;
}

.form_cardPatient {
  width: 100%;

  .send-container {
    .item2 {
      width: 100%;
      input {
        width: 345px;
      }
    }
  }
}

.card-PatientCard {
  .button-create {
    margin-top: 25px;
    padding-left: 10px;
  }
}

.import_search {
  font: italic normal 600 13px/20px "Poppins";
  text-align: center;
  font-size: 1rem;
}

@media only screen and (min-width: 2000px) and (max-width: 2300px) {
  .form_cardPatient {
    .send-container {
      .item2 {
        input {
          width: 400px;
        }
      }
    }
  }

  .dropdown-menu {
    width: 400px;
  }
}
@media only screen and (min-width: 2300px) and (max-width: 3000px) {
  .form_cardPatient {
    .send-container {
      .item2 {
        input {
          width: 500px;
        }
      }
    }
  }

  .dropdown-menu {
    width: 500px;
  }
}

@media (max-width: 1800px) {
  .card-PatientCard {
    .button-create {
      width: 250px;
      padding-left: 10px;
    }
  }

  .form_cardPatient {
    .send-container {
      .item2 {
        input {
          width: 270px;
        }
      }
    }
  }

  .dropdown-menu {
    width: 270px;
  }
}

@media (max-width: 1650px) {
  .form_cardPatient {
    .send-container {
      .item2 {
        input {
          width: 250px;
        }
      }
    }
  }

  .dropdown-menu {
    width: 250px;
  }
}

@media only screen and (min-width: 1250px) and (max-width: 1600px) {
  .card-PatientCard {
    .button-create {
      width: 200px;
      padding: 0;
      margin-right: 25px !important;
      a {
        font-size: 0.7vw;
      }
    }
  }
  .button-create {
    position: relative;
  }
}

@media (max-width: 1600px) {
  .card-PatientCard {
    padding-left: 2px !important;

    .import-button {
      width: 150px;
    }
  }
  .form_cardPatient {
    .send-container {
      .item2 {
        input {
          width: 230px;
        }
      }
    }
  }

  .dropdown-menu {
    min-width: 0;
    width: 230px;
  }
}

@media (max-width: 1250px) {
  .card-PatientCard {
    padding-left: 2px !important;

    .import-button {
      width: 150px;
    }
  }
  .form_cardPatient {
    .send-container {
      .item2 {
        width: 60%;
        margin-left: 10%;
        input {
          width: 100%;
        }
      }
    }
  }

  .send-container {
    margin: auto;
  }

  .dropdown-menu {
    min-width: 0;
    width: 59%;
  }
}

@media (max-width: 600px) {
  .card-PatientCard {
    .button-create {
      width: 170px;
      padding: 0;
      a {
        font-size: 1.8vw;
      }
    }
  }
  .button-create {
    position: relative;
  }
}
