.table-users-grid {
  display: grid;
  grid-template-columns: 25fr 45fr 25fr 50fr;
  text-align: left;
}
.table-users-grid-title {
  font-family: Poppins;
  text-align: left;
  font-weight: normal;
  color: #8D9899;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 8px;
  letter-spacing: 0px;
  border-bottom: 1px solid #657273;
}