.send_date_div {
  .item3 {
    input {
      width: 70px;
    }
  }
  .item4,
  .item1 {
    input[type="radio"] {
      /* Add if not using autoprefixer */
      -webkit-appearance: none;
      /* Remove most all native input styles */
      appearance: none;
      /* For iOS < 15 */
      background-color: var(--form-background);
      /* Not removed via appearance */
      margin: 0;

      font: inherit;
      color: currentColor;
      width: 1.15em;
      height: 1.15em;
      border: 0.15em solid currentColor;
      border-radius: 50%;
      transform: translateY(-0.075em);

      display: grid;
      place-content: center;
    }

    input[type="radio"]::before {
      content: "";
      width: 0.65em;
      height: 0.65em;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--form-control-color);
      background-color: orange;
    }

    input[type="radio"]:checked::before {
      transform: scale(1);
    }
  }
}

.PatientUnit,
.sendTo {
  cursor: pointer;
}
.setFreeText {
  width: 110%;
}

form {
  textArea {
    color: rgb(98, 98, 98);
    font-size: 1rem;
    padding: 5px;
  }
}

.prev_card {
  position: absolute;
  background: #faf8f5 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 20px #00000029;
  border: 1px solid #99896b;
  border-radius: 15px;
  padding: 10px;
  max-width: 450px;
  min-width: 250px;
  width: 100%;
  z-index: 2000;
  left: 94%;

  h3 {
    font-size: 1.2rem;
    font-weight: bold;
  }
  p {
    font-size: 1rem;
    font-weight: normal;
  }
}

@media (max-width: 1600px) {
  .card-ConseilCard {
    padding: 0px !important;
  }

  .setFreeText {
    width: 120%;
  }
  .send_date_div {
    .item2 {
      .send-date {
        width: 130px;
      }
    }
  }
}

@media (max-width: 1350px) {
  .setFreeText {
    width: 105%;
  }
  .send_date_div {
    .item3 {
      input {
        width: 50px;
      }
    }
  }
}
