.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1040;
  opacity: 1;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  background: #0f21342e 0% 0% no-repeat padding-box;
}

.blur-10 {
  opacity: 1;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.modal-body {
  display: flex;
  flex-direction: column;
}

.PatientUnit {
  padding-left: 10px;
  margin-top: 0;
}

.css-1s2u09g-control {
  background: #edf2f280 0% 0% no-repeat padding-box !important;
  border: 1px solid #d3e0e0 !important;
  border-radius: 10px !important;
  padding: 2px;
  margin: 0 !important;
  width: 100% !important;
}

.loupe {
  margin-left: 5px;
}

.trait {
  border: 1px solid #edf6f7;
}
.input-patient-search {
  background: #f0f2f2 0% 0% no-repeat padding-box;
  border: 1px solid #8d9899;
  border-radius: 10px;
  height: 60px;
  padding: 12px 20px;
  margin-top: 10px;
}

.input-patient-search::selection {
  border: 1px solid #5bb9c1 !important;
}

.sendTo {
  padding: 10px;
  color: #5bb9c1;
  font: normal normal bold 14px/21px Poppins;
  border-radius: 0px 0px 10px 10px;
}

.sendTo:hover {
  background: #cacaca 0% 0% no-repeat padding-box;
  transition: 0.5s;
}

.input_change_patient {
  font-family: "Poppins";
  margin-top: 10px;
  display: grid;
}

.input_change_patient > input {
  padding: 10px;
  width: 90%;
}

.button_update {
  margin: auto;
  margin-top: 25px;
}

.container-nav {
  display: flex;
  width: 100%;
  padding: 25px 50px 25px 50px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: flex-end;
}

.gender {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 80%;
  align-items: center;
  align-content: stretch;
  padding-bottom: 15px;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  padding: 35px;
  padding-bottom: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  align-items: center;
}

.patient_modal {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 24px;
  width: 100%;
  max-width: 1000px;
  margin: auto;
  z-index: 100;
  position: relative;
  padding: 1rem 5rem 1rem 5rem;
}

.create_conseil_modal {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 24px;
  margin-top: 25px;
  max-width: 1113px;
  margin: auto;
  z-index: 100;
  position: relative;
  padding: 1rem 5rem 1rem 5rem;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  color: #000;
  cursor: pointer;
  border: none;
  background: transparent;
}

.modal_title1 {
  text-align: left;
  font: normal normal bold 26px/39px Poppins;
  color: #5bb9c1;
}

.modal_title2 {
  text-align: left;
  font: normal normal bold 26px/39px Poppins;
  color: #0b243c;
}

.icon_button {
  background-color: transparent;
}

.text_champs {
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #657273;
}

.align_box {
  display: flex;
}

.checkbox_type {
  background: #f7f6f2 0% 0% no-repeat padding-box;
  width: 22px;
  height: 22px;
}

.checkbox_text {
  text-align: left;
  font: normal normal normal 16px Poppins;
  color: #657273;
}

.line {
  border: 0.1px dashed #8d9899;
  width: 100%;
  height: 0px;
  margin-top: 35px;
}

.input_modals {
  background: #edf2f280 0% 0% no-repeat padding-box;
  border: 1px solid #d3e0e0;
  border-radius: 10px;
  padding: 20px;
  width: 694px;
  height: 42px;
  margin: 10px 0px 10px 0px;
}

.checkbox_modals {
  background: #edf2f2 0% 0% no-repeat padding-box;
  border: 1px solid #d3e0e0;
  border-radius: 5px;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  line-height: 10px;
}

.label_checkbox {
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.modal_title3 {
  text-align: left;
  font: normal normal 600 20px/30px Poppins;
  color: #23595d;
  margin-top: 30px;
}

.delete_advice_button {
  border: none;
  background-color: inherit;
  cursor: pointer;
  display: inline-block;
  cursor: 'pointer';
  font: normal normal bold 16px/25px Poppins;
  color: #FF3100;
  text-align: right;
}

.disable_delete_advice_button {
  box-shadow: none;
  border: none;
  background-color: inherit;
  display: inline-block;
  font: normal normal bold 16px/25px Poppins;
  color: #FF3100;
  text-align: right;
  cursor: not-allowed;
}

.disable_delete_advice_button:hover {
  opacity: 1;
  cursor: not-allowed;
}

.disable_delete_advice_button > * {
  pointer-events: none;
}

.delete_advice_button:hover {
  text-decoration: underline;
}

.modal_title_edit_pack {
  text-align: left;
  font: normal normal 600 20px/30px Poppins;
  color: #23595d;
}

.textarea_modals {
  background: #edf2f280 0% 0% no-repeat padding-box;
  border: 1px solid #d3e0e0;
  border-radius: 10px;
  width: 694px;
  height: 159px;
  margin-bottom: 20px;
}

.file_modals {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px dashed #8d9899;
  border-radius: 12px;
  width: 695px;
  height: 140px;
  display: inline-block;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-bottom: 30px;
}

.file_modals p {
  display: inline-block;
  font: italic normal 600 13px/20px Poppins;
  color: #657273;
}

.modal_file_button {
  z-index: 1;
  margin-top: 10px;
  background: #0b243c 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 338px;
  height: 35px;
  position: relative;
  right: 0px;
  color: white;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  font-size: 13px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  line-height: 34px;
}

.modal_file_button_text {
  text-align: center;
}

.accept_format {
  margin-top: 10px;
  text-align: center;
  font: italic normal normal 13px/16px Montserrat;
  color: #4d4d4d;
}

.field_star {
  text-align: center;
  font: normal normal normal 16px/25px Poppins;
  color: #657273;
}

::-webkit-scrollbar-track {
  background: #0f21342e;
}

.button_modals_class {
  box-shadow: 2px 6px 10px #e6a55c66;
  border-radius: 18px;
  text-align: center;
  font: normal normal bold 16px/25px Poppins;
  color: #ffffff;
  height: 51px;
  margin: 30px 10px 10px 10px;
  padding: 0px 30px 0px 30px;
}

.orange_button {
  background: #ffa500 0% 0% no-repeat padding-box;
}

.grey_button {
  background: #8d9899 0% 0% no-repeat padding-box;
}

.button_modals {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
