.Profession_container {

  .css-b62m3t-container {
    width: 100%;
  }

  .css-1s2u09g-control {
    border-radius: 15px;
    height: 54px;
  }

  .css-1pahdxg-control {
    height: 54px;
  }
}
.Profil_comp {
  padding-left: 64px;
  // padding-right: 64px;
}

.container_form {
  input {
    padding: 10px;
  }
}

@media (max-width: 1250px) {
  .scheduled {
    width: 100%;
    .row {
      width: 100%;
    }
  }
  .row {
    flex-direction: column;
  }
  .Profil_comp {
    width: 97%;
    margin-bottom: 25px;
    .card {
      padding-left: 12px !important;
      padding-right: 12px !important;

      .box_menu {
        flex-direction: row;
        margin-left: 0;

        .align_bar {
          margin-left: 25px;
        }
      }
    }
  }
}
