.select_options {
  font-family: "Poppins";
}

.filter-col1 {
  select {
    width: 245px;
  }
}

.ToMuchPopUp {
  display: flex;
  flex-direction: column;
  width: 1113px;
  height: 430px;
  top: 200px;
  right: 20%;
  padding: 25px;
  border-radius: 24px;
  z-index: 1500;
  background-color: white;
  box-shadow: 22px 10px 8px -3px rgba(0, 0, 0, 0.1);

  position: absolute;

  .toMuchContent {
    display: flex;
    width: 640px;
    margin: auto;
    flex-direction: column;
  }

  button {
    width: 146px;
  }

  .button_PopUp {
    margin: auto;
    margin-top: 25px;
  }
  .list_ToMuch {
    list-style: none;
    display: grid;
    grid-template-columns: 50% 50%;

    li {
      display: flex;

      input {
        margin-top: auto;
        margin-bottom: auto;
      }
      p {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 20px;
      }
    }
  }
}

@media (max-width: 1700px) {
  .filter-col1 {
    select {
      width: 200px;
    }
  }
  .ToMuchPopUp {
    right: 10%;
  }
}

@media (max-width: 1500px) {
  .ToMuchPopUp {
    width: 800px;
    right: 30%;
  }
  .filter-col2 {
    width: 110%;
    margin-right: 15px;
  }
  .filter-col1 {
    width: 110%;
    margin-left: 25px;
    select {
      width: 160px;
    }
  }
}

@media (max-width: 770px) {
  .container {
    .list {
      .button-create {
        margin-top: 35px;
      }
    }
  }
  .filter-col1 {
    display: flex;

    .select_options {
      width: 100px !important;
    }
  }
}
