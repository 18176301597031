.Col_conseil {
  padding-right: 64px;
  height: 100%;
}

.col_card {
  padding-left: 84px;
}

.card_tutoList {
  height: 100%;
}

@media (max-width: 1400px) {
  .Col_conseil {
    padding-right: 44px;
  }
  .col_card {
    padding-left: 44px;
  }
}

@media (max-width: 1250px) {
  .rows {
    flex-direction: column;
  }
  .col_card {
    width: 100%;
    padding-left: 54px;
    padding-right: 54px;
  }
  .Col_conseil {
    margin: auto;
    width: 100%;
    padding-left: 54px;
    padding-right: 54px;
  }
}
@media (max-width: 800px) {
  .col_card {
    width: 100%;
    padding-left: 22px;
    padding-right: 22px;
  }
  .Col_conseil {
    margin: auto;
    width: 100%;
    padding-left: 22px;
    padding-right: 22px;

    .col-md-12 {
      padding: 0;
    }
  }

  @media (max-width: 650px) {
    .rows {
      flex-direction: column;
    }
    .card_tutoList {
      .container {
        padding: 0;
      }
    }

    .FlexContainer-body-collapse,
    .FlexContainer-body {
      margin-left: 10px;
    }
    .FlexContainer-r {
      margin-left: 10px;
    }
  }
}
