.resetpassword_modal {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: auto;
  padding: 50px;
  border-radius: 25px;

  .input_reset_pass {
    margin-top: 25px;
    text-align: center;
    display: flex;
  }

  .login-button {
    margin: auto;
    margin-top: 25px;
  }
}
