.grey-button {
  border-radius: 18px;
  text-align: center;
  font: normal normal bold 16px/25px Poppins;
  color: #ffffff;
  height: 51px;
  margin: 0px 0px 0px 0px;
  padding: 0px 30px 0px 30px;
}

.enable-grey-button {
  background: #8D9899 0% 0% no-repeat padding-box;
}

.disable-grey-button {
  box-shadow: none;
  background: #8d9899 0% 0% no-repeat padding-box;
  cursor: not-allowed;
}

.disable-grey-button:hover {
  opacity: 1;
}

.disable-grey-button > * {
  pointer-events: none;
}

@keyframes increase-opacity-effect {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}