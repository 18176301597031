.profile_menu {
  background: #f7fcfc 0% 0% no-repeat padding-box;
  border-radius: 24px;
}

.bars {
  border: 1px solid #e6ebf0;
  width: 262px;
  height: 1px;
}

.align_bar {
  display: flex;
  flex-direction: column;
}

.box_menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10%;
  margin-top: 5%;
}

.box_menu p {
  text-align: left;
  font: normal normal medium 16px/50px Poppins;
  color: #5bb9c1;
  margin-top: 5px;
  margin-bottom: 5px;
}

.back_button {
  background: #5bb9c1 0% 0% no-repeat padding-box;
  border-radius: 10px;
  line-height: 24px;
  width: 200px;
  height: 40px;
  text-align: center;
  margin-left: 20px;
}

.button-create {
  z-index: 1;
  margin-top: 14px;
  background: #0b243c 0% 0% no-repeat padding-box;
  box-shadow: 2px 6px 10px #0f213467;
  border-radius: 18px;
  width: 312px;
  height: 36px;
  position: absolute;
  right: 0px;
  color: white;
  text-align: center;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  font-size: 13px;
  display: block;
  margin-left: auto;
  margin-right: 40px;
  line-height: 34px;
}

.box_update {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 0.1fr);
  gap: 0px 0px;
  margin: 30px 0px 0px 90px;
}

.left_profile {
  grid-area: 1;
}
.right_profile {
  grid-area: 1;
}

.update-button {
  background: #ffa500 0% 0% no-repeat padding-box;
  box-shadow: 2px 6px 10px #e6a55c66;
  border-radius: 18px;
  width: 188px;
  height: 51px;
  text-align: center;
  font: normal normal bold 16px/25px Poppins;
  color: #ffffff;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.title_box {
  text-align: left;
  font: normal normal 600 20px/30px Poppins;
  letter-spacing: 0px;
  color: #23595d;
  margin-top: 30px;
}

.container_form {
  display: flex;
  flex-direction: column;
}

.first_section {
  display: flex;
  flex-direction: row;
}

.secon_section {
  display: flex;
  flex-direction: row;
}

.last_section {
  grid-area: 5 / 1 / 11 / 3;
}

.first_param {
  display: flex;
  flex-direction: row;
}

.identity_name {
  grid-area: 1 / 1 / 2 / 2;
}

.first_name {
  width: 50%;
}

.last_name {
  width: 50%;
}

.password_name {
  grid-area: 3 / 1 / 4 / 2;
}

.first_pass {
  width: 50%;
}

.second_pass {
  width: 50%;
}

.param_name {
  grid-area: 5 / 1 / 6 / 2;
}

.profession {
  width: 50%;
}

.signature {
  grid-area: 7 / 1 / 8 / 3;
}

.spe_main {
  grid-area: 8 / 1 / 9 / 3;
}

.spe_other {
  grid-area: 9 / 1 / 10 / 3;
}

.patho {
  grid-area: 10 / 1 / 11 / 3;
}

.oblig {
  font: normal normal normal 16px/25px Poppins;
  color: #657273;
  margin-top: 25px;
}

.button_area {
  margin-top: 5px;
}

.phone {
  width: 50%;
}

.title_input {
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  color: #657273;
  margin: 15px 0px 10px 0px;
}

.spec_main_profile {
  display: flex;
  flex-direction: column;
}

.spec_main_profile > .css-b62m3t-container > .css-1s2u09g-control {
  margin: 0 !important;
}

.spec_other_profile {
  display: flex;
  flex-direction: column;
}

.spec_other_profile > .css-b62m3t-container > .css-1s2u09g-control {
  margin: 0 !important;
}

.title_input > .css-b62m3t-container > .css-1s2u09g-control {
  margin: 0 !important;
}

.input-form {
  background: #d4e0e01a 0% 0% no-repeat padding-box;
  border: 1px solid #d4e0e0;
  border-radius: 15px;
  width: 80%;
  height: 56px;
  margin: 10px 0px 0px 0px;
}

.input-form2 {
  background: #d4e0e01a 0% 0% no-repeat padding-box;
  border: 1px solid #d4e0e0;
  border-radius: 15px;
  width: 90%;
  height: 56px;
  margin: auto;
}

.input-form2::placeholder {
  text-align: left;
  font: italic normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #657273;
  text-indent: 10px;
}

.input-form::placeholder {
  text-align: left;
  font: italic normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #657273;
  text-indent: 10px;
}

.react-tag-input {
  background: #edf2f280 0% 0% no-repeat padding-box;
  border: 1px solid #d3e0e0;
  border-radius: 10px;
  width: 90%;
}

.react-tag-input__tag {
  background: #d3e0e0 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.react-tag-input__tag__remove {
  background: #d3e0e0 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.react-tag-input__tag__content {
  text-align: left;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #4d473d;
}
